import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../components/Navigation/NavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL, CATEGORY_PATH } from "../utility/path";
import { getConfig, getConstant, handleErrorResponse, handleHeader } from "../services/functions";
import { spinner } from "../redux/slices/spinnerSlice";
import axios from "axios";
import UploadImage from "../components/UploadImage";
import MainContainer from "../components/MainContainer";
import { showAlert } from "../redux/slices/alertSlice";
import imageCompression from 'browser-image-compression';
import { listCategoryAction } from "../redux/slices/categoriesSlice";

const ChangeCategoryIcon = ({ }) => {

  const dispatch = useDispatch();
  const authData = useSelector(state => state?.auth?.data);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(undefined);

  const data = location.state;
  const [state, setState] = useState({
    categoryId: data?.categoryId,
    categoryName: data?.categoryName
  });

  const handleChangePhoto = input => async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    if (!file) return;

    try {
      setSelectedFile(URL.createObjectURL(file));

      // Compression options
      const options = {
        maxSizeMB: 1, // Set max size to 1MB
        maxWidthOrHeight: 1024, // Resize to a maximum of 1024px width or height
        useWebWorker: true, // Use Web Workers for faster compression
      };

       // Compress image
       const compressedFile = await imageCompression(file, options);
       //console.log('file.size',file.size/1024/1024);
       //console.log('Original File Size:', (file.size / 1024 / 1024).toFixed(2), 'MB');
       //console.log('Compressed File Size:', (compressedFile.size / 1024 / 1024).toFixed(2), 'MB');

       // Update state with compressed file
       //setSelectedFile(URL.createObjectURL(compressedFile));
       setState({ ...state, [input]: compressedFile });

    } catch (error) {
      console.error('Error while compressing image:', error);
    }

    
  }

  const handleClick = async (event) => {
    event.preventDefault();
    dispatch(spinner(true));
    const config = getConfig("multipart/form-data");

    try {
      let formData = new FormData();
      formData.append("id", state.categoryId);
      formData.append("image", state.image);
      const { data } = await axios.post(`${BASE_URL}/api/categories/updateIcon`, formData, config);
      
      dispatch(spinner(false));
      dispatch(showAlert({ message: 'Success', success: true }));
      dispatch(listCategoryAction({}));
      navigate(CATEGORY_PATH);
      
      return data;
    } catch (error) {
      console.log(error?.message)
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
      return errorResponse;
    }
  }

  const renderForm = () => {
    return (
      <>
        <UploadImage selectedFile={selectedFile} handleChange={handleChangePhoto} />
        <button type="button" className="btn btn-primary" onClick={(e) => handleClick(e)} disabled={authData?.loading || !selectedFile}>{authData?.loading ? "Loading.." : "Save"}</button>
      </>
    );
  }

  useEffect(() => {
    handleHeader({...getConstant("CHANGE_CATEGORY_ICON")},dispatch);

    return () => {}
  }, []);

  return (
    <>
      <NavBar />
      <MainContainer>
        {renderForm()}
      </MainContainer>
    </>
  );
}

export default ChangeCategoryIcon;
