import { showAlert } from "../redux/slices/alertSlice";
import Fingerprint2 from 'fingerprintjs2';
import { addAnalyticAction } from "../redux/slices/analyticSlice";
import forge from 'node-forge';
import { HOME_PATH, PROFILE_PATH, SUBSCRIPTION_PATH, MANAGE_SUBSCRIPTION_PLAN_PATH, TNC_PATH, CATEGORY_PATH, HOME_SEARCH_PATH } from "../utility/path";
import { setHeader } from "../redux/slices/headerSlice";



export const getGeoLocation = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
}

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map;
}

export function isMobile(){
  if(window?.cordova?.platformId){
    return window?.cordova?.platformId == "android";
  }
  return false;
}

export function onresize() {
  //your code here
  //this is just an example
  let width = document.body.clientWidth;
  let height = document.body.clientHeight;

  //localStorage.removeItem("isMobileView");
  
  //console.log(width);
  if(width < 752){
    localStorage.setItem("isMobileView",true);
    window.isMobileView = true;
    return true;
  }
  localStorage.setItem("isMobileView",false);
  window.isMobileView = false;
  return false;
}

export function getDistanceFromLatLonInKm(location,user) {
  const lat1 = location?.latitude;
  const lon1 = location?.longitude;
  const lat2 = user?.location?.coordinates?.length > 0 ? user?.location?.coordinates[0] : null; 
  const lon2 = user?.location?.coordinates?.length > 0 ? user?.location?.coordinates[1] : null;

  if(!lat1 || !lon1 || !lat2 || !lon2){
    return null;
  }

  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  return d.toFixed(2);
}

export function deg2rad(deg) {
  return deg * (Math.PI/180)
}


export function firebaseConfig() {
  return {apiKey: "AIzaSyBqKCowH3kvOBClc8ZpwF-ZwcuQXzdd8QI",
  authDomain: "ccapp-373211.firebaseapp.com",
  projectId: "ccapp-373211",
  storageBucket: "ccapp-373211.appspot.com",
  messagingSenderId: "47558031988",
  appId: "1:47558031988:web:6e83be97a736e8258e6f21",
  measurementId: "G-Y08MCK5150"};
};

export function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function jsonEmpty(obj){
  return Object.keys(obj).length < 1;
}

export function sleep(ms) {new Promise((r) => setTimeout(r, ms))};
//how to call -> await sleep(500);


export function getConfig(contentType){
  const userLoginFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : {nextStep:1,isLogggedIn:false,token:""};

  const deviceId = localStorage.getItem("deviceId") ? localStorage.getItem("deviceId") : "";
  let config = {
    headers: {
      "Content-Type": contentType ? contentType : "application/json",
      "authorization": `${"bearer "+userLoginFromStorage.token}`,
      "deviceId": deviceId
    },
  };
  if(deviceId){
    config.headers.deviceId = deviceId;
  }

  return config;
}

export function getConfigV1(contentType,authData){
  let token = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).token : "";
  
  if(authData?.token){
    token = authData?.token;
  }
  

  const deviceId = localStorage.getItem("deviceId") ? localStorage.getItem("deviceId") : "";
  let config = {
    headers: {
      "Content-Type": contentType ? contentType : "application/json",
      "authorization": `bearer ${token}`,
      "deviceId": deviceId
    },
  };
  if(deviceId){
    config.headers.deviceId = deviceId;
  }

  return config;
}

export const handleErrorResponse = (error, dispatch) => {
  console.log(error?.response)
  if (!error.response) {
    if(dispatch){dispatch(showAlert({ message: error.message, success: false }))};
    throw error;
  }

  if(dispatch){dispatch(showAlert({ message: error?.response?.data?.message, success: false }))};
  
  return {
    message: error?.response?.data?.message || error?.message || "Error",
    nextStep: error?.response?.data?.nextStep || '',
    // isLoggedIn : error?.response?.data?.isLoggedIn || '',
    status: error?.response?.data?.status || '',
    mobile: error?.response?.data?.mobile || ''
  };
};

export const getDeviceId =  () => {
  const oldDeviceId = localStorage.getItem("deviceId");
  if(oldDeviceId){
    return new Promise((resolve,rejest)=>{
      resolve(oldDeviceId);
    });
  }

  return new Promise((resolve,rejest)=>{
    Fingerprint2.get((components) => {
      const values = components.map((component) => component.value);
      const deviceId = Fingerprint2.x64hash128(values.join(''), 31);
      localStorage.setItem("deviceId",deviceId);
      resolve(deviceId);
    });
  })
};


export const logoutErrors = ['Invalid token', 'Missing Token'];

export async function runAnalytic(categoryToAdd, authData,dispatch,page,producer,searchCategoryId) {
  console.log('run analytics')
  if(!categoryToAdd || categoryToAdd.length < 2){
    return;
  }
  const searchCategoryList = localStorage.getItem("searchCategoryList");
  const deviceId = localStorage.getItem("deviceId");
  const location = localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")) : { latitude: -1, longitude: -1 };

  let req = {user: null, isLoggedIn: false,deviceId,searchCategory: categoryToAdd,location,page,producer,searchCategoryId};

  if (authData?.isLoggedIn) {
    req.user = authData.id;
    req.isLoggedIn = authData.isLoggedIn;
  }

  if (searchCategoryList) {
    const existingCategories = JSON.parse(searchCategoryList);
    if (!existingCategories.includes(categoryToAdd)) {
        const updatedCategories = [...existingCategories, categoryToAdd];
        localStorage.setItem("searchCategoryList", JSON.stringify(updatedCategories));
        dispatch(addAnalyticAction(req))
    }
  } else {
      localStorage.setItem("searchCategoryList", JSON.stringify([categoryToAdd]));
      dispatch(addAnalyticAction(req))
  }
}

export async function runAnalytic_v2({categoryToAdd, authData,dispatch,page,producer,searchCategoryId,sourcePage}) {
  //console.log('runAnalytic_v2',categoryToAdd,page,authData,'searchCategoryId',searchCategoryId,'producer',producer);

  const searchCategoryList = localStorage.getItem("searchCategoryList");
  const deviceId = localStorage.getItem("deviceId");
  const location = localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")) : { latitude: -1, longitude: -1 };

  let req = {user: null, isLoggedIn: false,deviceId,searchCategory: categoryToAdd,location,page,producer,searchCategoryId};

  if (authData?.isLoggedIn) {
    req.user = authData.id;
    req.isLoggedIn = authData.isLoggedIn;
  }

  if(producer){
    dispatch(addAnalyticAction(req));
  }else  if (searchCategoryList) {
    if(!categoryToAdd || categoryToAdd?.length < 3){
      return;
    }
    
    const existingCategories = JSON.parse(searchCategoryList);
    if (!existingCategories.includes(categoryToAdd)) {
        const updatedCategories = [...existingCategories, categoryToAdd];
        localStorage.setItem("searchCategoryList", JSON.stringify(updatedCategories));
        dispatch(addAnalyticAction(req))
    }
  } else {
      if(!categoryToAdd || categoryToAdd.length < 2){
        return;
      }
      localStorage.setItem("searchCategoryList", JSON.stringify([categoryToAdd]));
      dispatch(addAnalyticAction(req))
  }
}

export function encryptPassword(password) {
  if(!password){
    return password;
  }

  const publicKeyContent = `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwhFqo5b/MrTQ+pAl4rnb
  yjVBQ3YFcYQa3gQ7j4KtKqjMbEyN69bitC4GnOIoPrWUNq0EnfXA+6pTJn0cqxJ9
  vfQN4hgOOMaanKIqH9YDZouVaCo+sGgx+ey1hF5V7U0PgnUpDO6VQRHt9PmDJxSg
  aH1kFvWW2ZHst8jpBO/qvlBwtFepWLuy7kzJQ/FPnWGUyYSlol8AEUq/lp5xbem7
  ZVfVtATrCElC8PzEuuwc6gBbi8PKxtQt23bM/K6KM44eUTVMkPn2quM7zwi4bjav
  XNI0YbhtWxmax5/8AX/DLvK6NEkySRJS0agJTBj2N/Bqs5x2i0FdcGNYcXLAqNIl
  xwIDAQAB
  -----END PUBLIC KEY-----`;

  const publicKey = forge.pki.publicKeyFromPem(publicKeyContent);
  const passwordBytes = forge.util.encodeUtf8(password);
  const encryptedPasswordBytes = publicKey.encrypt(passwordBytes);
  const encryptedPasswordBase64 = forge.util.encode64(encryptedPasswordBytes);
  
  return encryptedPasswordBase64;
;

}


export const callGetGeoLocation = async () => {
  console.log('callGetGeoLocation')
  try{
    const position = await getGeoLocation();
    const data = {
      latitude:position.coords.latitude,
      longitude:position.coords.longitude,
      altitude:position.coords.altitude,
      accuracy:position.coords.accuracy,
      altitudeAccuracy:position.coords.altitudeAccuracy,
      heading:position.coords.heading,
      speed:position.coords.speed,
      timestamp:position.timestamp
    }
    localStorage.setItem("location",JSON.stringify(data));
  }catch(error){
      console.log("callGetGeoLocation,error, ",error);
      localStorage.setItem("location",JSON.stringify({latitude:-1,longitude:-1}));
  }
}

export function displayDateTime(value){
  if(!value){
    return;
  }
  return new Date(value).toLocaleString();
}

export function displayDate(value){
  if(!value){
    return;
  }
  return new Date(value).toLocaleString();
}

export function displayTime(value){
  if(!value){
    return;
  }
  return new Date(value).toLocaleString();
}


export function validatePincode(pincode){
  if(!pincode){
    return false;
  }

  const pincodeRegex = /^[1-9][0-9]{5}$/; // Regular expression for 6-digit pincode
  if (pincode.match(pincodeRegex)) {
    // Valid pincode
    return true;
  }

  return false;
}

export function isDigit(value){
  if(!value){
    return false;
  }
  if (isNaN(value)) {
    return false;
  }
  return true;
}

export const validateMobile = (number,countryCode) => {
  console.log(number)
  if(!number){
    return false;
  }
  const pattern = /^[6-9]\d{9}$/;
  return pattern.test(number);
};

export function validateServiceCharge(inputValue){
// ^ asserts the start of the string.
// (?!0\d{4}$|5000$) is a negative lookahead to exclude the numbers starting with 0 (e.g., 01234) and the exact number 5000.
// \d{1,4} matches between 1 and 4 digits (for numbers less than 5000).
// $ asserts the end of the string.

  // if(!inputValue  || inputValue > 5000 || inputValue < 1){
  //   return false;
  // }
  //console.log(inputValue);
  if(!inputValue){
    return false;
  }
  
  const regex = /^(?!0$)(?!0\d{0,3}$|5000$)\d{1,4}$/;

  return regex.test(inputValue);
  
}

const CONSTANTS = {
  'HOME':{currentPageName:"", gotoName:"Home",gotoAction:HOME_PATH},
  'HOME_SEARCH_PATH':{currentPageName:"", gotoName:"Home",gotoAction:HOME_SEARCH_PATH},
  'PROFILE':{currentPageName:"Profile", gotoName:"Home",gotoAction:HOME_PATH},
  'CHANGE_PHOTO':{currentPageName:"Change Photo", gotoName:"Profile",gotoAction:PROFILE_PATH},
  'CHANGE_PASSWORD':{currentPageName:"Change Password", gotoName:"Profile",gotoAction:PROFILE_PATH},
  'CHANGE_ADDRESS':{currentPageName:"Change Address", gotoName:"Profile",gotoAction:PROFILE_PATH},
  'CHANGE_SERVICE':{currentPageName:"Change Service", gotoName:"Profile",gotoAction:PROFILE_PATH},

  'CATEGORY':{currentPageName:"Category", gotoName:"Home",gotoAction:HOME_PATH},
  'CHANGE_CATEGORY_ICON':{currentPageName:"Category Icon", gotoName:"Category",gotoAction:CATEGORY_PATH},
  'PUSH_NOTIFICATION':{currentPageName:"Push Notification", gotoName:"Home",gotoAction:HOME_PATH},
  'SUBSCRIPTION':{currentPageName:"Subscription", gotoName:"Home",gotoAction:HOME_PATH},
  'SUBSCRIPTION_DETAILS': {currentPageName:"Subscription Details", gotoName:"Subscription",gotoAction:SUBSCRIPTION_PATH},
  'MANAGE_SUBSCRIPTION_PLAN': {currentPageName:"Manage Subscription Plan", gotoName:"Home",gotoAction:HOME_PATH},
  'MANAGE_NOTIFICATION': {currentPageName:"Manage Notifications", gotoName:"Home",gotoAction:HOME_PATH},
  'NOTIFICATIONS':{currentPageName:"Notifications", gotoName:"Home",gotoAction:HOME_PATH},
  'CONTACT_US':{currentPageName:"Contact Us", gotoName:"Home",gotoAction:HOME_PATH},
  'ABOUT_US':{currentPageName:"About Us", gotoName:"Home",gotoAction:HOME_PATH},
  'TNC':{currentPageName:"Terms & Conditions", gotoName:"Home",gotoAction:HOME_PATH},
  'PRIVACY_POLICY':{currentPageName:"Privacy Policy", gotoName:"Home",gotoAction:HOME_PATH},
  'FAVOURITES':{currentPageName:"Favourties", gotoName:"Home",gotoAction:HOME_PATH},
  
  'DETAILS':{currentPageName:"Details", gotoName:"Home",gotoAction:HOME_PATH},
  
  'VERIFY_MOBILE':{currentPageName:"Verify Mobile", gotoName:"Home",gotoAction:HOME_PATH},
  'VERIFY_OTP':{currentPageName:"Verify OTP", gotoName:"Home",gotoAction:HOME_PATH},
  'VERIFY_CAPTCHA':{currentPageName:"Verify Captcha", gotoName:"Home",gotoAction:HOME_PATH},
  'VERIFY_PASSWORD':{currentPageName:"Verify Password", gotoName:"Home",gotoAction:HOME_PATH},
  'ENTER_DETAILS':{currentPageName:"Enter Details", gotoName:"Home",gotoAction:HOME_PATH},
  'SET_PASSWORD' : {currentPageName:"Set new password", gotoName:"Home",gotoAction:HOME_PATH},

}

export function getConstant(type){
  return CONSTANTS[type] || ""
}

export const handleHeader = (data, dispatch) => {
  dispatch(setHeader(data));
};

export function generateRandomPassword(){
  return Math.random().toString().substr(2, 6);
}

export const inputTypeNumber = (input,maxLength) => {
  const inputValue = input;
  const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
  const truncatedValue = numericValue.slice(0, maxLength); // Limit to x characters
  return truncatedValue;
};

export const validatePincode_v2 = async (value, setState) => {
  const pincodePattern = /^[1-9][0-9]{5}$/;

  if (!pincodePattern.test(value)) {
    setState((prevState) => ({
      ...prevState,
      errorpincode: "Invalid PIN code format. Please enter a valid 6-digit PIN code.",
      pincodeError: "Invalid PIN code format. Please enter a valid 6-digit PIN code.",
      postOffice: ""
    }));
    return false;
  }

  try {
    const response = await fetch(`https://api.postalpincode.in/pincode/${value}`);
    const data = await response.json();
    

    if (data[0].Status === "Success") {
      setState((prevState) => ({ ...prevState, errorpincode: "", postOffice:data[0]?.PostOffice[0], pincodeError:"" }));
      return true;
    } else {
      setState((prevState) => ({
        ...prevState,
        errorpincode: "Invalid PIN code. Please enter a valid 6-digit PIN code.",
        pincodeError: "Invalid PIN code. Please enter a valid 6-digit PIN code.",
        postOffice: ""
      }));
      return false;
    }
  } catch (error) {
    setState((prevState) => ({
      ...prevState,
      errorpincode: "Error validating PIN code. Please try again later.",
      pincodeError: "Error validating PIN code. Please try again later.",
      postOffice: ""
    }));
    return false;
  }
};

const PLAN = {
  1: { amount: 140, label: 'Starter plan' },
  2: { amount: 238, label: 'Medium term plan' },
  3: { amount: 315, label: 'Long term plan' },
};

export const getPlanAmount = (plan) => {
  return PLAN[plan]?.amount || null;
}

export const getPlanLabel = (plan) => {
  return PLAN[plan]?.label || null;
}

export const getCardColorIdx = (idx) => {
  return idx % 6;
}

const SUBSCRIPTION_STATUS = {
  0: { label: 'Inactive' },
  1: { label: 'Active' },
  2: { label: 'Pending' },
  3: { label: 'Error' },
  4: { label: 'Expired' },
  5: { label: 'Cancelled' },
};

export const getSubscriptionStatusLabel = (code) => {
  return SUBSCRIPTION_STATUS[code]?.label || null;
}

export const PAGE_CONSTANT = {
  SEARCH_PAGE: 0,
  DETAILS_PAGE: 1,
  DETAILS_PAGE_CALL_CLICK: 2,
};
