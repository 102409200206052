import { useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import OtpComponent from "../components/OtpComponent";
import { getConstant, handleHeader } from "../services/functions";
import { useDispatch, useSelector } from "react-redux";
import H from "../components/H";
import { verifyOtpAction } from "../redux/slices/authSlice";
import { listCategoryAction } from "../redux/slices/categoriesSlice";
import SumVerification from "../components/SumVerification";

const EnterOtp = ({ nextStep, previousStep, handleChangeOtp, state, vendor }) => {
  const Continue = e => {
    e.preventDefault();
    //nextStep();
    dispatch(verifyOtpAction({...state,locationPathname:location?.pathname, vendor:vendor}));
    dispatch(listCategoryAction(authData));
  }

  const authData = useSelector(state => state?.auth?.data);
  const [showTransition,setShowTransition] = useState(false);
  const dispatch = useDispatch();
  const [verified,setVerified] = useState(true);
  

  useEffect(()=>{
    setShowTransition(true);
    handleHeader({...getConstant("VERIFY_CAPTCHA")},dispatch);
    return ()=>{setShowTransition(false)}
  },[]);

  const handleVerification = (isVerified) => {
    if (isVerified) {
      //alert("You passed the verification!");
      handleHeader({...getConstant("VERIFY_OTP")},dispatch);
      setVerified(true);
    } else {
      setVerified(false);
    }
  };

  return (
    <div className={`show-transition ${showTransition ? "show" : ""}`}>
      {!verified && <H value={"Verify Captcha"}/>}
      {verified && <H value={"Verify OTP"}/>}

      {/* <CustomInput title="" id="otpId" type="text" placeholder="Enter otp" handleChange={handleChange} name="otp" value={state?.otp}/> */}
      {!verified && <SumVerification onVerify={handleVerification}/>}
      {verified && <OtpComponent handleChangeOtp={handleChangeOtp}/>}

      {verified && <button className="btn btn-primary m-0" onClick={Continue} disabled={!state.otp || state.otp.length!=4}>Submit</button>}
      {<button className="btn btn-primary m-2" onClick={previousStep}>Back</button>}
    </div>
  );
}

export default EnterOtp;
