import { useSelector } from 'react-redux';
import loupe from '../loupe.png';
import { BASE_APP_URL, BASE_URL, SUBSCRIPTION_PATH } from '../utility/path';
import { useNavigate } from 'react-router-dom';
import { isMobile } from '../services/functions';

const CustomLogo = ({ users }) => {
  const navigate = useNavigate();
  const authData = useSelector(state => state?.auth?.data);
  
  const handleClick = async (event) => {
    if (event) event.preventDefault(); // Ensure event exists before calling preventDefault()

    if (isMobile() && typeof cordova !== "undefined" && cordova?.InAppBrowser) {
      cordova.InAppBrowser.open(`${BASE_APP_URL}?r=${SUBSCRIPTION_PATH}`, "_system");
    } else {
      navigate(SUBSCRIPTION_PATH);
    }
  } 

  return (
    <>
      {authData?.isLoggedIn && authData?.role === 'producer' && !authData?.subscribed && 
      <div className="card caution p-1 m-1"><div className="heading__link" onClick={handleClick}>
      <small><i className="fa fa-exclamation-circle fa-lg" aria-hidden="true"></i>&nbsp;<strong>Reminder:</strong>Your account is currently inactive.<br/>Subscribe to activate your account. <strong>Click Here!!</strong></small></div></div>
      }

      <h1 className='m-0'>
        Gaufi
        {/* <span className="overlap-container">
          <img src={loupe} className="loupe-image" alt="Loupe" />
          <span className="overlap-i">i</span>
        </span> */}
      </h1>
      <small className="">Find skilled professionals near you..</small>
    </>
  );
}

export default CustomLogo;

