import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { detailsAction } from "../redux/slices/detailsSlice";
import Alert from '../components/Alert';
import { addPostAction, postsAction } from '../redux/slices/postsSlice';
import { addRatingAction } from '../redux/slices/ratingsSlice';
import NavBar from '../components/Navigation/NavBar';
import CustomInput from '../components/CustomInput';
import CommentModel from '../components/CommentModel';
import Rating from '../components/Rating';
import StarRating from '../components/StarRating';
import { followAction, unfollowAction } from '../redux/slices/usrSlice';
import CustomIcon from '../components/CustomIcon';
import CustomImage from '../components/CustomImage';
import { PAGE_CONSTANT, handleErrorResponse, runAnalytic, runAnalytic_v2, validatePincode_v2 } from '../services/functions';
import logo from '../logo192.png';

const Details = () => {
  const { id } = useParams();

  const authData = useSelector(state => state?.auth?.data);
  const detailsData = useSelector(state => state?.details?.data);
  const details = useSelector(state => state?.details);
  const postsData = useSelector(state => state?.posts?.data);


  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  const [isShowInit, setIsShowInit] = useState(false);
  const [state, setState] = useState({ id: authData?.id, otherUserId: id, comment: " ", rating: '', postOffice: '' });

  const commentsSlice = detailsData?.commentByOthers?.slice(0, 2);
  const commentByOthersData = detailsData?.commentByOthers;
  const commentsCount = detailsData?.commentByOthers?.length;

  const ratingByOthersData = detailsData?.ratingByOthers || [];
  const ratingByOthersGroupByData = groupBy(ratingByOthersData, rating => rating.rating);
  const alreadyRated = ratingByOthersData.filter((el) => {
    return el.user[0] === authData?.id
  }).length;

  const alreadyFollowing = detailsData?.followers?.find(u => u?.toString() === authData?.id?.toString());
  const hideFollow = detailsData?.followers?.find(u => u?.toString() == authData?.id?.toString());
  const hideMobile = detailsData?.category?.find(u => u?.name?.toLowerCase() === "user");
  
  useEffect(() => {
    let ignore = false;
    runAnalytic_v2({categoryToAdd:detailsData.category?.[0]?.name, authData,dispatch,page:PAGE_CONSTANT.DETAILS_PAGE,producer:state?.otherUserId,searchCategoryId:detailsData.category?.[0]?.id})
    
    const fetchData = async () => {
      if (!ignore) {
        dispatch(detailsAction({ ...state }));
        //await validatePincode_v2(detailsData?.pincode, setState);
      }
    };

    fetchData();

    return () => { ignore = true; }
  }, [id]);

  const handleSubmit = (type, rating) => e => {
    e.preventDefault();
    //setIsShowInit(true);
    console.log("submit", state,authData);

    if(!authData || !authData?.isLoggedIn){
      handleErrorResponse({ message: "Please login!!" }, dispatch);
      return;
    }

    if (type === "post") {
      dispatch(addPostAction({ ...state, comment:state?.comment?.trim() }));
      setState({ ...state, comment: "" })
    }

    if (type === "rating") {
      dispatch(addRatingAction({ ...state, rating: rating }));
      setState({ ...state, rating: "" })
    }

    if (type === "follow") {
      dispatch(followAction({ ...state }));
    }

    if (type === "unfollow") {
      dispatch(unfollowAction({ ...state }));
    }



    // setTimeout(() => {
    //   console.log('timeout')
    //   setIsShowInit(false);
    // }, 5000);

  }

  const handleClose = (e) => {
    e.preventDefault();
    setIsShowInit(false);
  };

  const handleChange = input => e => {
    e.preventDefault();
    setState({ ...state, [input]: e.target.value });
  }

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  const handlePhoneCall = () => {
    // Replace `1234567890` with the actual phone number
    runAnalytic_v2({categoryToAdd:detailsData?.category?.[0]?.name, authData,dispatch,page:PAGE_CONSTANT.DETAILS_PAGE_CALL_CLICK,producer:detailsData?.id,searchCategoryId:detailsData?.category?.[0]?.id})
    if (window?.cordova && window?.cordova?.InAppBrowser) {
      cordova.InAppBrowser.open(`tel:${detailsData.mobile}`, "_system");
    } else {
      window.location.href = "tel:" + detailsData.mobile;
    }
  };

  const handleWhatsApp = () => {
    // Replace `1234567890` with the actual phone number
    window.location.href = "https://api.whatsapp.com/send?phone="+detailsData.mobile;
  };

  const openCommentModal = async () => {
    const modalElement = document.getElementById('commentModal');
    if(modalElement){
      $(modalElement).modal('show'); // For Bootstrap 4
      modalElement.focus();
    }
  }


  return (
    <>
      <NavBar />

      <div className="main-container">
        <div className="d-flex flex-column align-items-center">
          {!details.loading && !details.appErr && !details.serverErr ? (<>
            <div className="mt-3 mb-3">
              {/* <CustomImage className="circle" src="https://picsum.photos/300/300"/> */}
              <CustomImage state={{image:detailsData?.profilePhoto,name:detailsData.name}} className={"detail_page__image"} size={"18rem"}/>
            </div>

            {(authData?.id !== detailsData?.id && alreadyFollowing  && !hideMobile) ? 
            (<p>
              <button className="btn btn-outline-warning mb-3" onClick={handleSubmit("unfollow")}>Unfollow</button>
            </p>) : ''}

            {(authData?.id !== detailsData?.id  && !alreadyFollowing && !hideMobile) ? 
            (<p>
              <button className="btn btn-outline-info mb-3" onClick={handleSubmit("follow")}>Follow</button></p>
            ) : ''}

            <div className="d-flex flex-column text-break">
              <div className="capitalize"><CustomIcon type="user_v1" />&nbsp;{detailsData.name}</div>
              {!hideMobile ? (<div className="capitalize d-flex align-items-center">
                <CustomIcon type="address_v1" />
                <div>
                {detailsData?.address && <span>&nbsp;{detailsData.address},</span>}
                {detailsData?.pincode && <span>&nbsp;{detailsData.pincode},</span>}
                {detailsData?.postOffice?.District && <span>&nbsp;{detailsData.postOffice.District},</span>}
                {detailsData?.postOffice?.Division && <span>&nbsp;{detailsData.postOffice.Division},</span>}
                {detailsData?.postOffice?.State && <span>&nbsp;{detailsData.postOffice.State},</span>}
                {detailsData?.postOffice?.Country && <span>&nbsp;{detailsData.postOffice.Country}</span>}
                </div></div>) : ''}

              {!hideMobile ? (<div><CustomIcon type="mobile_v1" />&nbsp;<a href="#" onClick={handlePhoneCall}>Call</a></div>) : ''}
              {/* <p><CustomIcon type="whatsapp" />&nbsp;<a href={`https://api.whatsapp.com/send?phone=${detailsData.mobile}`} onClick={handleWhatsApp}>{detailsData.mobile}</a></p> */}


              {!hideMobile ? (<div className="capitalize">
                <img src={detailsData?.category?.[0]?.photo || logo} alt={detailsData?.category?.[0].name} className="category-image" onError={(e) => {e.target.src = logo;}}/>
                &nbsp;{detailsData?.category?.[0].name}</div>) : ''}
              {/* {!hideMobile ? (<p><CustomIcon type="currency" />&nbsp;{detailsData.fee} <i><small>(service charge* )</small></i></p>) : ''} */}
              {!hideMobile && detailsData?.aboutme ? <div className="capitalize-first"><CustomIcon type="aboutme" />&nbsp;{detailsData?.aboutme}</div> : ''}
              
            </div>

            {!hideMobile ? (<Rating ratingByOthersGroupByData={ratingByOthersGroupByData} ratingByOthersData={ratingByOthersData} />) : ''}
            <button type="button" className="btn btn-outline-primary mb-3" disabled={commentByOthersData?.length < 1} onClick={openCommentModal}>
                        View reviews({commentByOthersData?.length})
            </button>

            <CommentModel commentByOthersData={commentByOthersData} />
            {!hideMobile  && alreadyRated < 1 && authData?.id !== detailsData?.id ? (<StarRating handleSubmit={handleSubmit} />) : ''}

            {/* <CustomInput title="" id="ratingId" type="number" placeholder="Enter rating" handleChange={handleChange} name="rating" value={state.rating}/> */}
            {/* <button className="btn btn-outline-primary mb-3" onClick={handleSubmit("rating")}>Post ratings</button> */}

            {authData?.id !== detailsData?.id && !hideMobile ? (<>
              <CustomInput title="Post comments" id="postId" type="text" placeholder="type comment here..." handleChange={handleChange} name="comment" value={state.comment} />
              <button className="btn btn-outline-primary mb-3" onClick={handleSubmit("post")} disabled={!state.comment}>Post comments</button>
            </>) : ''}
          </>) : ''}

          {!details.loading && (details.appErr || details.serverErr) ? (<div>Seems some issue, please try again later!!</div>) : ""}
        </div>
      </div>

    </>
  );
}

export default Details;
