import React, { useEffect, useState } from "react";
import NavBar from "../components/Navigation/NavBar";
import { getCardColorIdx, getConfigV1, getConstant, handleHeader } from "../services/functions";
import { BASE_URL, DETAILS_PATH } from "../utility/path";
import axios from "axios";
import MainContainer from "../components/MainContainer";
import { useDispatch, useSelector } from "react-redux";
import CustomImage from "../components/CustomImage";
import { useNavigate } from "react-router-dom";
import logo from '../logo.png';

const Favourite = () => {
    const [state, setState] = useState();
    const authData = useSelector((state) => state?.auth?.data);
    const [showTransition, setShowTransition] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        handleHeader({ ...getConstant("FAVOURITES") }, dispatch);
        setShowTransition(true);
        loadData();

        return () => { setShowTransition(false) }
    }, []);

    const handleClick = input => e => {
        console.log('input',input);
        if(input){
            return navigate(input);
        }
    }

    const loadData = async () => {
        try {
            const config = getConfigV1('',authData);
            const { data } = await axios.get(
                `${BASE_URL}/api/users/profile?id=${authData?.id}`,          
                config
              );
            //console.log(data);

            if (data) {
                setState(data);
            }

        } catch (error) {
            console.log(error?.message)
        }
    }

    return (

        <>
            <NavBar />
            <MainContainer>
            <h3>Favourites</h3>
            <div className="cards_ct">
            {state?.following?.map((el,idx) => (
                <div key={idx} className={`card_ct card-${getCardColorIdx(idx)} show-transition ${showTransition ? "show" : ""}`} onClick={handleClick(`/details/${el?.id}`)}>
                    <div className="card__icon d-flex" >
                        <CustomImage state={{ image:el?.profilePhoto || logo, name: el?.name }} className="thumbnail circle" size={"3rem"} />
                        <div className="p-1 text-truncate">
                            {el?.name?.toLowerCase()} 
                        </div>
                    </div>
                    <div className="card__title">{el?.category[0]?.name}</div>
                    <p className="card__apply">
                        <span className="card__link">Contact</span>
                    </p>
                </div>      
            ))}
            {!state?.following?.length && (<li className="notification-item bg-light mb-2 p-1 border border-light rounded">No favourites available</li>)}
            </div>

            </MainContainer>
        </>
    );
};

export default Favourite;