import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { displayDateTime, getConfig, handleErrorResponse } from "../services/functions";
import MainContainer from "../components/MainContainer";
import { spinner } from "../redux/slices/spinnerSlice";
import { BASE_URL } from "../utility/path";
import axios from "axios";
import NavBar from "../components/Navigation/NavBar";
import { useNavigate } from "react-router-dom";
import { showAlert } from "../redux/slices/alertSlice";

const AnalyticsManagment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth.data);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(5);
  const [dataList, setDataList] = useState([]);
  const [address, setAddress] = useState();

  const loadData = async () => {
    console.log('load')
    try {
      const config = getConfig();
      const resp  = await axios.post(
        `${BASE_URL}/api/analytic/lista`,
        {limit,skip},
        config
      );

      //console.log('data:',resp?.data.data);

      if(resp?.data?.data?.length > 0){
        setDataList((prevList) => [...prevList, ...resp?.data?.data]); // Append new data
      }else{
        setLimit((prevSkip) => 0); // Update skip based on fetched data length
      }
      setSkip((prevSkip) => prevSkip + resp?.data?.data?.length || 0); // Update skip based on fetched data length

    } catch (error) {
      console.error("Error loading data:", errorResponse);
    }
  };
  
  useEffect(() => {
    setDataList([]);
    loadData()
  },[]);

  const handleClick = (input,id) => e => {
    e.preventDefault();
    console.log(input,id);
    return;
    
    if(!input || input?.coordinates?.length != 2 || input?.coordinates?.[0] === -1 ){
      return;
    }

    try { 
      axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${input?.coordinates[0]}&lon=${input?.coordinates[1]}`)
      .then(response => {
        const address = response.data.display_name;
        //setAddress(address);
        console.log(address);

        const updated = dataList.map(ct => {
          if (ct.id === id) {
            return { ...ct, address};
          }
          return ct;
        });
        setDataList(updated);

      })
      .catch(error => {
      console.error(error);
      });

    } catch (error) {
      
    }

    
  }
  

  return (
    <>
      <NavBar />
      <MainContainer>
        <h3>Analytics Managment</h3>
        {authData?.isLoggedIn && authData?.isAdmin ? (
          <>
          <div>{dataList?.length}</div>
          {
          dataList?.map((el,index)=>(
              <div key={index} className="card mb-1">
                <div className="card-header">Date:{displayDateTime(el?.createdAt)}</div>
                <div className="card-body">
                  {el?.user && el?.user[0]?.name && <div><strong>User:</strong>{el?.user[0]?.name}</div>}
                  {el?.user && el?.user[0]?.mobile && <div><strong>Mobile:</strong>{el?.user[0]?.mobile}</div>}
                  {el?.user && el?.user[0]?.pincode && <div><strong>Pincode:</strong>{el?.user[0]?.pincode}</div>}
                  {el?.searchCategory && <div><strong>Searched for:</strong>{el?.searchCategory}</div>}
                  {el?.page && <div><strong>From page:</strong>{el?.page}</div>}
                  {el?.producer && el?.producer[0]?.name && <div><strong>Skilled User:</strong>{el?.producer[0]?.name} ({el?.producer[0]?.mobile}) ({el?.producer[0]?.category?.[0]?.name})</div>}
                </div>
                <div className="card-footer">
                  {/* <button className="btn btn-outline-primary btn-sm" onClick={handleClick(el?.location,el?.id)}>Get Location</button> */}
                </div>
              </div>
          ))}
          <button className="btn btn-outline-primary btn-sm mt-2 mb-2" onClick={loadData} disabled={limit < 1}>view more</button>
          </>
        ) : (
          <div>You are not authorized to view this page.</div>
        )}
      </MainContainer>
    </>
  );
};

export default AnalyticsManagment;