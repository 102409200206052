import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {Provider, useDispatch} from 'react-redux';
import store from './redux/store/store';

import {getDeviceId, getGeoLocation, onresize} from './services/functions';
import Spinner from './pages/Spinner';
import Alert from './components/Alert';
import Main from './Main';

const root = ReactDOM.createRoot(document.getElementById('root'));

const callGetGeoLocation = async () => {
  console.log('callGetGeoLocation');
  let locationData = { latitude: -1, longitude: -1 }; // Default location
  try{
    const position = await getGeoLocation();
    locationData = {
      latitude:position.coords.latitude,
      longitude:position.coords.longitude,
      altitude:position.coords.altitude,
      accuracy:position.coords.accuracy,
      altitudeAccuracy:position.coords.altitudeAccuracy,
      heading:position.coords.heading,
      speed:position.coords.speed,
      timestamp:position.timestamp
    };
  }catch(error){
      console.log("callGetGeoLocation,error, ",error);
  }
  localStorage.setItem("location",JSON.stringify(locationData));
}

// Render React DOM
const renderReactDom = async () => {
  root.render(
  <Provider store={store}>
      <Main />
  </Provider>
);
};

const registerServiceWorker = async () =>{
  console.log("registerServiceWorker");
  if('serviceWorker' in navigator){
    console.log('service worker is available')
    navigator.serviceWorker.register('/sw.js')
    .then(function(){
      console.log('service worker registered')
    });
  }
}

// Cordova Initialization
const initCordova = () => {
  document.addEventListener(
    'deviceready',
    () => {
      console.log('Cordova is ready');
      renderReactDom();

      // setTimeout(() => {
      //   navigator?.splashscreen?.hide();
      // }, 1000);
    },
    false
  );
};

// Main Initialization
if (window.cordova) {
  //navigator?.splashscreen?.show();
  initCordova();
} else {
  renderReactDom()
} 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
callGetGeoLocation();
reportWebVitals();
registerServiceWorker();
getDeviceId();