import { useDispatch, useSelector } from "react-redux";
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import { displayDateTime, getCardColorIdx, getConfig, getDistanceFromLatLonInKm, runAnalytic } from "../services/functions";
import {BASE_URL, LOGOUT_PATH, REGISTER_PATH, USER_MANAGEMENT_PATH } from "../utility/path";
import CustomImage from "./CustomImage";
import ListItem from "./ListItem";
import { locationSaveAction } from "../redux/slices/locationSlice";
import { useEffect, useState } from "react";
import logo from '../logo192.png';
import useImageCache from "../services/useImageCache";
import { showAlert } from "../redux/slices/alertSlice";
import axios from "axios";
import { spinner } from "../redux/slices/spinnerSlice";

// const location = localStorage.getItem("location")
// ? JSON.parse(localStorage.getItem("location"))
// : {latitude:-1,longitude:-1};

const Card = ({ idx, user, openDetailsModal }) => {
    const authData = useSelector(state => state?.auth?.data);
    const location = useSelector(state => state?.location?.data || { latitude: -1, longitude: -1 });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const urllocation = useLocation();
    const { image, loading } = useImageCache(user?.profilePhoto);

    const ratingByOthersData = user?.ratingByOthers || [];
    const ratingByOthersGroupByData = groupBy(ratingByOthersData, rating => rating.rating);

    const averageRating = (1 * ratingByOthersGroupByData?.get(1)?.length +
        2 * ratingByOthersGroupByData?.get(2)?.length +
        3 * ratingByOthersGroupByData?.get(3)?.length +
        4 * ratingByOthersGroupByData?.get(4)?.length +
        5 * ratingByOthersGroupByData?.get(5)?.length) / ratingByOthersData?.length || 0;

    const averageRatingRound = Math.round(averageRating * 10) / 10;
    const isUserManagementPath = urllocation?.pathname === USER_MANAGEMENT_PATH;

    function groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    const handleClick = input => e => {
        e.preventDefault();
        if(isUserManagementPath){
            console.log('return')
            return;
        }
        if(!authData?.isLoggedIn){
            dispatch(showAlert({ message: "Please login to view details!!", success: "warning" }));
            localStorage.setItem("redirect",`/details/${input}`);
            return navigate(LOGOUT_PATH);
        }
        if (authData?.isLoggedIn) {
            //runAnalytic(user.id, authData, dispatch, 1, user.id, user?.category[0]?.id);
            openDetailsModal(input);
            return;
            //return navigate(input)
        }
        return navigate(REGISTER_PATH);
    }

    const handleClick_CheckDistance = () => {
        console.log('check distance')
        dispatch(locationSaveAction({}))
    }

    const handleClick_ApplyPromo = user => async e => {
        try {
            const config = getConfig();
            const resp  = await axios.post(
              `${BASE_URL}/api/payments/cpfa`,
              {user},
              config
            );
            dispatch(showAlert({ message: resp?.data?.message, success: true }));
        }catch (error) {
            console.error("Error loading data:", error);
            dispatch(showAlert({ message: error?.response?.data?.message, success: false }));
        }
    }
    
    const [showTransition, setShowTransition] = useState(false);
    useEffect(() => {
        setShowTransition(true);

        return () => { setShowTransition(false) }
    }, []);

    return (
        <>
            {/* non-admin */}
            {user && !isUserManagementPath && (<div className={`card_ct card-${getCardColorIdx(idx)} show-transition ${showTransition ? "show" : ""}`} onClick={handleClick(`${user.id}`)}>
                <div className="card__icon d-flex" >
                    <CustomImage state={{ image:image || logo, name: user?.name }} className="thumbnail circle" size={"3rem"} />
                    <div className="p-1 text-truncate">
                        {user?.name?.toLowerCase()} 
                    </div>
                </div>

                {/* <small className="card__fee">{user?.fee ? "Service Charge* " + user?.fee + " INR" : ""}</small> */}
                <small className="card__fee">&nbsp;</small>

                <div className="card__title">{user?.category[0]?.name}</div>

                {/* non-admin */}
                <ul className="card__ratings p-0">
                    {location?.latitude > -1 && user?.location?.coordinates?.length > 0 ? 
                        <ListItem value={getDistanceFromLatLonInKm(location, user) + " km away"} /> :
                        // <ListItem value={"Click here to see distance"} handleClick={handleClick_CheckDistance} />
                        ''
                    }
                    {ratingByOthersData?.length > 0 && <ListItem value={ratingByOthersData?.length + " Ratings"} />}
                    {user?.commentByOthers?.length > 0 && <ListItem value={user?.commentByOthers?.length + " Reviews"} />}
                    {user?.followers?.length > 0 && <ListItem value={user?.followers?.length + " Followers"} />}
                </ul>

                <p className="card__apply">
                    {/* <Link className="card__link" to={`/details/${user.id}`}>Details</Link> */}
                    {/* <span className="card__link" onClick={handleClick(`/details/${user.id}`)}>Contact</span> */}
                    <span className="card__link">Contact</span>
                </p>
            </div>)}

            {/* admin */}
            {user && isUserManagementPath && authData?.isAdmin && (<div className={`card_ct card-${getCardColorIdx(idx)} show-transition ${showTransition ? "show" : ""}`}>
                <div className="card__icon d-flex" >
                    <CustomImage state={{ image:image || logo, name: user?.name }} className="thumbnail circle" size={"3rem"} />
                    <div className="p-1 text-truncate">
                        {user?.name?.toLowerCase()} 
                    </div>
                </div>

                {/* <small className="card__fee">{user?.fee ? "Service Charge* " + user?.fee + " INR" : ""}</small> */}
                <small className="card__fee">&nbsp;</small>

                <div className="card__title">{user?.category[0]?.name}</div>

                {/* admin */}
                <ul className="card__ratings p-0">
                    <ListItem value={"Call: "+user?.mobile} />
                    <ListItem value={"User status: "+user?.active} />
                    <ListItem value={"Payment: "+user?.subscribed} />
                    <ListItem value={"Date: "+displayDateTime(user?.createdAt)} />
                    <ListItem value={"Referral: "+user?.referral} />
                </ul>

                <p className="card__apply">
                    {user?.category[0]?.name !== "user" && !user?.subscribed  && <button className="btn btn-sm btn-primary" onClick={handleClick_ApplyPromo(user?.id)}>Active user</button>}
                </p>
            </div>)}
        </>
    );
}

export default Card;
