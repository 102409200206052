import { useEffect, useRef, useState } from "react";
import { ABOUT_US_PATH, CATEGORY_PATH, CONTACT_US_PATH, HOME_PATH, LOGOUT_PATH, MANAGE_NOTIFICATION_PATH, NOTIFCATION_PATH, PROFILE_PATH, PUSH_NOTIFICATION_PATH, REGISTER_PATH, SUBSCRIPTION_PATH, MANAGE_SUBSCRIPTION_PLAN_PATH, TNC_PATH, REGISTER_GENERAL_PATH, HOME_SEARCH_PATH, LIST_USER_PATH, USER_MANAGEMENT_PATH, CONTACT_US_MANAGEMENT_PATH, ANALYTICS_MANAGEMENT_PATH, FAVOURITES_PATH, BASE_URL, BASE_APP_URL } from "../../utility/path";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import "../../navbar.css";
import { logoutAction } from "../../redux/slices/authSlice";
import { isMobile, logoutErrors } from "../../services/functions";
import useDynamicDimension from "../../services/useDynamicDimension";
import CustomIcon from "../CustomIcon";
import CustomImage from "../CustomImage";
import { hideAlert } from "../../redux/slices/alertSlice";
// import logo from '../../logo.png';
import useImageCache from "../../services/useImageCache";
import logo from '../../logo192.png';
import useOnScroll from "../../services/useOnScroll";


const NavBar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const authData = useSelector(state => state?.auth?.data);
  const header = useSelector(state => state?.header?.data);
  const alert = useSelector(state => state?.alert);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const screenSize = useDynamicDimension();
  const { image, loading } = useImageCache(authData?.profilePhoto || logo);

  const { pageYOffset } = useOnScroll();
  const prevScrollpos = useRef(0); // Use useRef to store the previous scroll position

  //console.log('screenSize',location?.pathname,location?.pathname.indexOf('register'),authData?.nextStep,!authData?.isLoggedIn,location?.pathname.indexOf('login') < 0);



  // if (authData?.nextStep > 1 && !authData?.isLoggedIn && location?.pathname.indexOf('register') < 0) {
  //   if (location?.pathname.indexOf(LOGIN_PATH) < 0 && location?.pathname.indexOf(FORGOT_PWD_PATH) < 0) {
  //     dispatch(logoutAction({ source: "navbar" }));
  //   }
  // }


  const hideBackButton = location?.pathname == HOME_PATH || location?.pathname == HOME_SEARCH_PATH ;
  const backToHomePath = location?.pathname === HOME_PATH ? HOME_SEARCH_PATH : HOME_PATH;


  //console.log(window.isMobileView);

  //note: dont add login, because it's impact forgor pwd
  const logoutRequiredFor = ["logout"];

  const handleClick = (param) => (e) => {
    e.preventDefault();

    if (logoutRequiredFor.indexOf(param) > -1) {
      dispatch(logoutAction({ source: "handleClick" }));
    }

    if(param == "registervendor"){
      dispatch(logoutAction({ source: "handleClick" }));
    }

    if (param === "home") {
      return navigate(backToHomePath);
    }

    if (param === "notification") {
      return navigate(NOTIFCATION_PATH);
    }

    if (param === "subscription") {
      if(isMobile() && typeof cordova !== "undefined" &&  cordova?.InAppBrowser && !authData?.subscribed){
        cordova.InAppBrowser.open(`${BASE_APP_URL}?r=${SUBSCRIPTION_PATH}`, "_system");
      }
    }

    
    setIsNavExpanded(!isNavExpanded);
  }

  useEffect(() => {
    if (logoutErrors.includes(alert?.data?.message)) {
      //console.log('token error');
      dispatch(logoutAction({}));
      setTimeout(() => {
        dispatch(hideAlert({}));
        navigate(REGISTER_PATH);
      }, 1500)
    }
  }, [alert]);

  //Run effect when pageYOffset changes
  useEffect(() => {
    // Scroll logic
    const header = document.getElementById("header");
    if (!header) return;

    if (document.getElementsByClassName("navigation-menu expanded")?.length) {
      return;
    }

    //hide header on Modal screen
    if (document.getElementsByClassName("modal fade show").length) {
      header.classList.add("hidden");
      return;
    }
    //console.log('handle scroll,pre,', prevScrollpos?.current, 'curr', pageYOffset)

    if (prevScrollpos?.current > pageYOffset || pageYOffset < 1 || !pageYOffset) {
      header.classList.remove("hidden");
    } else {
      header.classList.add("hidden");
    }

    // Update the ref with the latest scroll position
    prevScrollpos.current = pageYOffset;
  }, [pageYOffset, prevScrollpos]); 

  return (
    <nav className="navigation justify-content-between px-3" id="header">
      {screenSize.isMobileView ?
        (<div>
          <a className="heading__link text-truncate" onClick={(e) => { e.preventDefault(); navigate(header.gotoAction) }}>
            {!hideBackButton && <CustomIcon type="chevron-left" />}
            <span className="vertical_align_middle">{header.gotoName}</span>
          </a>
        </div>) : <div>&nbsp;</div>
      }

      {/* Center header text */}
      {screenSize.isMobileView ?
        <div className="text-capitalize col-4 text-truncate text-center">{header?.currentPageName}</div> : <div>&nbsp;</div>
      }

      {/* Profile icon header */}
      {
        screenSize.isMobileView && authData?.isLoggedIn ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
             <div className="heading__link" onClick={handleClick("notification")}>
              <div className="notification-bell">
                <svg className="notification-bell-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <CustomIcon type="bell"/>
                </svg>
                <span className="notification-dot"></span>
              </div>
             </div>

              <div className="heading__link my-color-bg-light-1 rounded" onClick={handleClick("toggle")}>
                <CustomImage className="thumbnail circle" state={{
                  image: image,
                  name: authData?.name
                }} size={"2.7rem"} />
            </div>
          </div>
          ) :
          ''
      }

      {screenSize.isMobileView && !authData?.isLoggedIn ? (<div>
              {/* <button  className="border-0 bg-transparent" onClick={handleClick("toggle")}><img src={logo} alt="Logo" height="45" width="45" className="circle"></img></button> */}
              <button  className="btn btn-warning btn-sm" onClick={handleClick("toggle")}>Join</button>
            </div>) : ''}
      {!screenSize.isMobileView && !authData?.isLoggedIn ? (<div>
      </div>) : ''}

      <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
        <ul className="list-scrollable" style={{maxHeight: '100vh'}}>
          <li onClick={handleClick("home")}>
            <NavLink to={backToHomePath}>
              Home
            </NavLink>
          </li>

          {/* {!authData?.isLoggedIn ? (
            <li onClick={handleClick("login")}>
              <NavLink to={LOGIN_PATH}>
                Login
              </NavLink>
            </li>) : ''} */}

          {!authData?.isLoggedIn ? (
            <li onClick={handleClick("register")}>
              <NavLink to={REGISTER_PATH}>
                Register/ Login
              </NavLink>
            </li>) : ''}

            {authData?.isLoggedIn && authData?.isAdmin ? (
            <li onClick={handleClick("registervendor")}>
              <NavLink to={REGISTER_GENERAL_PATH}>
                Register Vendor
              </NavLink>
            </li>) : ''}

            {authData?.isLoggedIn && authData?.isAdmin ? (
            <li onClick={handleClick("usermanagement")}>
              <NavLink to={USER_MANAGEMENT_PATH}>
                User Management
              </NavLink>
            </li>) : ''}

            {authData?.isLoggedIn && authData?.isAdmin ? (
            <li onClick={handleClick("contactusmanagement")}>
              <NavLink to={CONTACT_US_MANAGEMENT_PATH}>
                Contact Us Management
              </NavLink>
            </li>) : ''}
            {authData?.isLoggedIn && authData?.isAdmin ? (
            <li onClick={handleClick("analyticsmanagement")}>
              <NavLink to={ANALYTICS_MANAGEMENT_PATH}>
                Analytics Management
              </NavLink>
            </li>) : ''}

          {authData?.isLoggedIn ? (
            <li onClick={handleClick("profile")}>
              <NavLink to={PROFILE_PATH}>
                Profile
              </NavLink>
            </li>) : ''}
  
          {authData?.isLoggedIn && authData?.role === "producer" ? (
            <li onClick={handleClick("subscription")}>
              {isMobile() && typeof cordova !== "undefined" && cordova?.InAppBrowser && !authData?.subscribed ? (<NavLink to={HOME_PATH}>
                Subscription
              </NavLink>) : (<NavLink to={SUBSCRIPTION_PATH}>
                Subscription
              </NavLink>)}
            </li>) : ''}

            {authData?.isLoggedIn && authData?.isAdmin ? (
            <li onClick={handleClick("category")}>
              <NavLink to={CATEGORY_PATH}>
                Category
              </NavLink>
            </li>) : ''}

            {authData?.isLoggedIn && authData?.isAdmin ? (
            <li onClick={handleClick("pushnotification")}>
              <NavLink to={PUSH_NOTIFICATION_PATH}>
                Push Notification
              </NavLink>
            </li>) : ''}

            {authData?.isLoggedIn && authData?.isAdmin ? (
            <li onClick={handleClick("managesubscriptionplan")}>
              <NavLink to={MANAGE_SUBSCRIPTION_PLAN_PATH}>
                Manage Subscription Plan
              </NavLink>
            </li>) : ''}

            {authData?.isLoggedIn && authData?.isAdmin ? (
            <li onClick={handleClick("managenotification")}>
              <NavLink to={MANAGE_NOTIFICATION_PATH}>
                Manage Notifications
              </NavLink>
            </li>) : ''}

            {!screenSize?.isMobileView && authData?.isLoggedIn ? (
            <li onClick={handleClick("notification")}>
              <NavLink to={NOTIFCATION_PATH}>
                Notifications
              </NavLink>
            </li>) : ''}

            {authData?.isLoggedIn && (authData?.role === "consumer" || authData?.isAdmin)  ? (
            <li onClick={handleClick("Favourites")}>
              <NavLink to={FAVOURITES_PATH}>
                Favourites
              </NavLink>
            </li>) : ''}

          <li onClick={handleClick("contactus")}>
            <NavLink to={CONTACT_US_PATH}>
              Contact Us
            </NavLink>
          </li>

          {!authData?.isLoggedIn ? (<li onClick={handleClick("aboutus")}>
            <NavLink to={ABOUT_US_PATH}>
              About Us
            </NavLink>
          </li>) : ''}

          <li onClick={handleClick("tnc")}>
            <NavLink to={TNC_PATH}>
              Terms & Conditions
            </NavLink>
          </li>

          {authData?.isLoggedIn ? (
            <li onClick={handleClick("logout")}>
              <NavLink to={LOGOUT_PATH}>
                Logout
              </NavLink>
            </li>) : ''}

         {screenSize?.isMobileView && <li>
            <div className="divider" />
          </li> }
          {screenSize?.isMobileView && <li className="bg-white p-5">
            
            <div className="follow-us px-4">
              <span className="follow-us-text">Follow Us:</span>
              <a
                href="https://www.instagram.com/gaufi.com_/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon instagram"
              >
                <i className="fa fa-instagram"></i>
              </a>

              <a
                href="https://www.facebook.com/profile.php?id=61569788811617"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon facebook"
              >
                <i className="fa fa-facebook-f"></i>
              </a>
              <a
                href="https://wa.me/7756074939"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon whatsapp"
              >
                <i className="fa fa-whatsapp" aria-hidden="true"></i>
              </a>
            </div>
          </li>}
          {screenSize?.isMobileView && <li className="m-4">
            <div className="divider" />
          </li>
           }
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;
