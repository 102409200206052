import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useLocation
} from "react-router-dom";

import Cards from "../components/Cards";
import NavBar from "../components/Navigation/NavBar";
import { getPaymentRequestStatus } from "../redux/slices/paymentSlice";
import { resetUserListAction } from "../redux/slices/usrSlice";

import { searchAction, usrAction } from "../redux/slices/usrSlice";
import { setHeader } from "../redux/slices/headerSlice";

import CustomSearch from "../components/CustomSearch";
import { getConstant, getGeoLocation, handleHeader } from "../services/functions";
import { HOME_SEARCH_PATH, LOGOUT_PATH, SUBSCRIPTION_DETAILS_PATH, SUBSCRIPTION_PATH } from "../utility/path";
import HomeCategories from "../components/HomeCategories";
import SearchModel from '../components/SearchModel';
import CustomLogo from "../components/CustomLogo";
import DynamicPlaceholder from "../components/DynamicPlaceholder";
import NoDataFound from "../components/NoDataFound";

const Home = ({ }) => {
  //const { users } = useLoaderData();

  const usersData = useSelector(state => state?.users?.data?.user);
  const usersLoading = useSelector(state => state?.users?.loading);
  const skipData = useSelector(state => state?.users?.data?.skip);
  const limitData = useSelector(state => state?.users?.data?.limit);
  const categoriesData = useSelector(state => state?.categories?.data);
  const authData = useSelector(state => state?.auth?.data);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [skip, setSkip] = useState(skipData);
  const [limit, setLimit] = useState(limitData);
  const [selectedCategory, setSelectedCategory] = useState();

  const dispatch = useDispatch();

  const handleCategorySelection = data => {
    if (selectedCategory === data?.id) return; // No update if category is unchanged

    setSelectedCategory((current) => { return data?.id });
    setSkip((current) => { return 0 });
    setUsers((current) => { return [] });

    if (data?.id) {
      dispatch(searchAction({ selectedCategory: data?.id, skip: 0, limit }));
      dispatch(setHeader({ currentPageName: data?.name, gotoName: "Home", gotoAction: HOME_SEARCH_PATH }))
      closeSearchModal();
    } else {
      dispatch(usrAction({ skip: 0, limit }));
    }
  }

  const appendUsers = useCallback((newUsers) => {
    if (newUsers?.length) {
      setUsers((currentUsers) => [...currentUsers, ...newUsers]);
    }
  }, []);

  useEffect(() => {
    appendUsers(usersData);
    setSkip(skipData);
    setLimit(limitData);
    return () => {}
  }, [usersData]);

  useEffect(() => {
    const txnId = searchParams && searchParams.get("txnId");
    const plan = searchParams && searchParams.get("plan");
    const redirect = searchParams && searchParams.get("r");
    if(redirect && redirect === SUBSCRIPTION_PATH){
      localStorage.setItem("redirect",SUBSCRIPTION_PATH);
      if(!authData?.id){
        navigate(LOGOUT_PATH);
      }else if(authData?.id){
        localStorage.removeItem("redirect");
        navigate(SUBSCRIPTION_PATH);
      }
    }

    if (txnId) {
      dispatch(getPaymentRequestStatus({ txnId, id: authData?.id, type: "transaction", plan }));
      navigate(SUBSCRIPTION_DETAILS_PATH);
    }
    handleHeader({ ...getConstant("HOME") }, dispatch);

    return () => { setSkip(0); setLimit(5); setUsers([]); dispatch(resetUserListAction({})) }
  }, []);

  const openSearchModal = () => {
    console.log('show');
    const modalElement = document.getElementById('searchModal');
    if (modalElement) {
      //const modal = bootstrap.Modal.getInstance(modalElement); // For Bootstrap 5
      //modal.hide();
      $(modalElement).modal('show'); // For Bootstrap 4

      // const modalElementHeader = document.getElementById('header');
      // modalElementHeader.classList.add('hidden');
    }
  };

  const closeSearchModal = async () => {
    console.log('hide');

    const modalElementHeader = document.getElementById('header');
    modalElementHeader.classList.remove('hidden');

    const modalElement = document.getElementById('searchModal'); // Replace with your modal's ID
    if (modalElement) {
      //const modal = bootstrap.Modal.getInstance(modalElement); // For Bootstrap 5
      //modal.hide();
      $(modalElement).modal('hide'); // For Bootstrap 4
    }
  };

  const renderViewMoreButton = (condition, onClickHandler) => {
    if (condition) {
      return (
        <div className="cards_ct">
          <button
            className="btn btn-outline-primary btn-sm m-3"
            disabled={usersLoading}
            onClick={onClickHandler}
          >
            {usersLoading === true ? 'loading..' : 'View more'}
          </button>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <NavBar />
      <div className="main-container">
        <div className="heading">
          <CustomLogo/>
          <div // Handle click event
            data-toggle="modal" data-target="#searchModal">
            {/* <CustomSearch categoriesData={[]} /> */}
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="form-group input-group mb-3 mt-3">
                {/* <input className="form-control custom-search-input" placeholder={"search electrician, mechanic, etc.."}
                       onClick={openSearchModal} autoComplete="off" tabIndex="-1" /> */}
                       <DynamicPlaceholder data={categoriesData} handleClick={openSearchModal} className={"form-control custom-search-input bg-light"} autoComplete={"off"}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!selectedCategory && 
        <HomeCategories handleClick={handleCategorySelection} />}
        <SearchModel handleClick={handleCategorySelection}></SearchModel>

        {selectedCategory && users?.length > 0 ? (
          <>
            <Cards users={users} />

            {renderViewMoreButton(limit > 0 && !selectedCategory, () => dispatch(usrAction({ skip, limit })))}
            {renderViewMoreButton(limit > 0 && selectedCategory, () => dispatch(searchAction({ selectedCategory, skip, limit })))}

          </>
        ) : <NoDataFound loading={usersLoading} selectedCategory={selectedCategory}/>}

      </div>
    </>
  );
}

export default Home;

export async function loaderLocation() {
  const position = await getGeoLocation();
  const data = {
    latitude: position.coords.latitude,
    longitude: position.coords.longitude,
    altitude: position.coords.altitude,
    accuracy: position.coords.accuracy,
    altitudeAccuracy: position.coords.altitudeAccuracy,
    heading: position.coords.heading,
    speed: position.coords.speed,
    timestamp: position.timestamp
  }
  return data;
}
